<script>
import { createEventDispatcher } from "svelte";
import Calendario from "./Calendario.svelte";

export let editField;
export let objectSource;

let posicaoClique = {x: 0, y: 0};
let strData;
let valorAnterior;
let mostrarCalendario = false;
const dispatch = createEventDispatcher();

function changeData() {
  if (strData && strData.trim().length == 8 || strData.trim().length == 10) {  
    try {          
      let dt;
      let ano;
      let mes;
      let dia;
      if (strData.trim().length == 8) {
        ano = Number(strData.substring(4, 8));
        mes = Number(strData.substring(2, 4)) - 1;
        dia = Number(strData.substring(0, 2));
      } else if (strData.trim().length == 10) {
        ano = Number(strData.substring(6, 10));
        mes = Number(strData.substring(3, 5)) - 1;
        dia = Number(strData.substring(0, 2));
      }                  
      if (isNaN(dia)) dia = 0;
      if (isNaN(mes)) mes = 0;
      if (isNaN(ano)) ano = 0;
      mes += 1;
      strData = (dia < 10 ? '0'+dia : dia) + '/' + (mes < 10 ? '0'+mes : mes) + '/' + (ano);      
      if (!validarData(dia, mes, ano)) {
        strData = null;
      }      
    } catch (error) {
      strData = null;      
    }
  } else {
    strData = null;
  }
  setObjectSource();
  dispatch('change', strData); 
}

function validarData(day, month, uear) {
  if (month < 1 || month > 12) { // check month range
    return false;
  }
  if (day < 1 || day > 31) {
    return false;
  }
  if ((month == 4 || month == 6 || month == 9 || month == 11) && day == 31) {
    return false;
  }
  if (month == 2) { // check for february 29th
    var isleap = (year % 4 == 0 && (year % 100 != 0 || year % 400 == 0));
    if (day > 29 || (day == 29 && !isleap)) {
      return false;
    }
  }
  return true;
}

function setObjectSource() {
  if (objectSource && editField) {
    if (strData) {
      objectSource[editField] = strData;
    } else {
      objectSource[editField] = null;
    }
  }
}

$: if (objectSource && editField) {
  if (objectSource[editField] && objectSource[editField] != valorAnterior) {     
    valorAnterior = objectSource[editField];      
    if (strData) {
      if (strData != objectSource[editField]) {
        strData = objectSource[editField];
      }
    } else {
      strData = objectSource[editField];
    }
  } else {      
    if (objectSource[editField] != valorAnterior) {
      strData = null;
      valorAnterior = null;
    }
  }
}

function clickCalendario(event) {
  posicaoClique.x = event.clientX;
  posicaoClique.y = event.clientY;
  mostrarCalendario = !mostrarCalendario;  
}

function changeDataCalendario(event) {  
  strData = event.detail.data;
  mostrarCalendario = false;  
  setObjectSource();
  dispatch('change', strData); 
}

function clickForaDoCalendario() {
  mostrarCalendario = false;
}

  $: outerWidth = 0
  $: innerWidth = 0
	$: outerHeight = 0
	$: innerHeight = 0


</script>

<svelte:window bind:innerWidth bind:outerWidth bind:innerHeight bind:outerHeight />

<div style="position: relative; width: fit-content;">
  <div class="hgroup" style="position: relative;" on:click={clickCalendario}>
    <!-- <input type="text" placeholder="dd/mm/aaaaa" style="width: 90px; padding: 5px; border: none; border-radius: 3px;" on:blur={changeData} bind:value={strData} maxlength="10"> -->
    <div style="width: 90px; padding: 5px;">{strData}</div>
    <button style="position: relative; right: 6px; top: 6px"><i class="far fa-calendar"></i></button>
  </div>
  {#if mostrarCalendario}
    <div  style="position: absolute; top: {posicaoClique.y}; left: {innerWidth-posicaoClique.x < 300 ? 'auto' : '0'}; right: {innerWidth-posicaoClique.x < 300 ? '5px' : 'auto'}; width: 300px;">
      <Calendario on:change={changeDataCalendario} bind:dataSelecionada={strData} on:clicouFora={clickForaDoCalendario}/>
    </div>    
  {/if}
</div>


<style>
  /* .hgroup {
    display: flex;
    flex-direction: row;
    width: fit-content;
    align-items: stretch;
  } */
  /* .btnDate {
    position: absolute;
    top: 2px;
    bottom: 2px;    
    right: 2px;
    border: none;
  } */
  /* input {
    text-align: center; 
    margin: 0;
  } */
  .hgroup {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  button {
    margin: 0; 
    margin-left: -1px;
  }
</style>

<svelte:options tag="svelte-datepicker" />