<script>
    import { createEventDispatcher, onDestroy, onMount } from "svelte";
    import { get_current_component } from "svelte/internal";    
	
    export let dataProvider = [];
    // export let labelField;
    export let colunas = [];
    export let mostrarLista = false;  
    export let scrollFocus = true;
    
    let textoBusca;
    let intervaloBusca;  
    let selected;
    let jaDisparou;
    let exameSelecionado;
    let edBusca;    
    let idxSel = -1;
    let elementLista;
    let refs = [];

    const component = get_current_component();
    const svelteDispatch = createEventDispatcher();
	const dispatch = (name, detail) => {
		svelteDispatch(name, detail);
		component.dispatchEvent && component.dispatchEvent(new CustomEvent(name, { detail }));
    }	
    
    onMount(async () => {});

	onDestroy(() => {
        if (intervaloBusca) {
            clearInterval(intervaloBusca);
        }
    });
			
	function entraFoco() {
        clickAbrir();
    }

    function saiFoco() {        
        this.mostrarLista = false;
    }

    function clickAbrir() {
        mostrarLista = true;               
    }

    function clickFecharLista() {
        mostrarLista = false;
    }

    function clickSeta() {        
        mostrarLista = !mostrarLista;        
    }

    function changeBuscaInterna(event) {        
        if (intervaloBusca) {
            clearInterval(intervaloBusca);
        }
        intervaloBusca = setInterval(() => {
            if (!selected) {
                dispatch("onSearch", edBusca.value);                                                
            }            
            clearInterval(intervaloBusca);
        }, 1000);
    }

    export function refresh() {
        if (dataProvider) {
            for (let i = 0; i < dataProvider.length; i++) {
                dataProvider[i]._refresh = new Date().getTime();
            }
        }
    }

    export function setFocus() {
        if (edBusca) {
            edBusca.focus();
        }
    }

    function clickItem(registro, idx) {
        selected = registro;
        selected._idx = idx;        
        mostrarLista = false;
        exameSelecionado = selected.nome;
        dispatch("change", registro);	
        textoBusca = "";
    }

    function onScroll(event) {		
		const body = event.target;		
		if (
			!jaDisparou &&
			body.offsetHeight + body.scrollTop >= body.scrollHeight * 0.95
		) {
			jaDisparou = true;				
			dispatch("onFinalScroll", {});			
			setTimeout(() => {
				jaDisparou = false;
			}, 1000);
		}
    }
    
    $: if (mostrarLista) {        
        if (selected && selected._idx != null) {
            idxSel = selected._idx;
        }
        selected = null;        
        setTimeout(() => {
            edBusca.focus();
            dispatch("onOpenLista", {idxSel: idxSel});            
            if (idxSel >= 0 && scrollFocus) {
                refs[idxSel].scrollIntoView();
            }
        }, 300);   
    }

</script>

<style>
    @import "./assets/fontawesome/css/all.css";	

    input {
        padding: 5px;
        margin: 5px;
        border: 1px solid #d3d3d3;
        border-radius: 3px;
        flex: 1;
        /* -webkit-transition: all 0.30s ease-in-out;
        -moz-transition: all 0.30s ease-in-out;
        -ms-transition: all 0.30s ease-in-out;
        -o-transition: all 0.30s ease-in-out; */
        outline: none;
        padding: 3px 0px 3px 3px;
        margin: 5px 1px 3px 0px;  
    }

    input:focus {
        box-shadow: 0 0 5px rgba(81, 203, 238, 1);
        padding: 3px 0px 3px 3px;
        margin: 5px 1px 3px 0px;
        border: 1px solid rgba(81, 203, 238, 1);
    }
    .lista {
        position: absolute;        
        top: 40px;
        left: 5px;
        right: 5px;
        max-height: 300px;
        border: 1px solid #d3d3d3;
        border-radius: 3px;
        overflow-y: auto;
        background-color: #f0f0f0;
        box-shadow: 2px 2px 2px #484848;
        z-index: 10000;
    }
    .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 2px;        
        border: 1px solid #f0f0f0;
        background-color: #fff;
        cursor: pointer;
        margin: 2px;
    }
    .hgroup {
        display: flex;
        flex-direction: row;
    }
    p {
        margin: 0;
        padding: 0;
    }
    .truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
    }
    .overlay {
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
    }
    .seta {
        position: absolute; 
        right: 15px; 
        top: 13px; 
        cursor: pointer;
        color: #d3d3d3;
    }

</style>

{#if 1 == 2}
    <p class="truncate"></p>
{/if}

<div class="hgroup" style="position: relative;">
    {#if selected && !mostrarLista}
        <input type="text" 
            bind:value={exameSelecionado}
            style="cursor: pointer; padding-right: 30px;"
            on:click={clickAbrir}
            readonly>
        <i class="fas fa-chevron-down seta"
            on:click={clickSeta}>
        </i>    
    {:else}
        <input type="text" 
            style="padding-right: 30px;"
            bind:this={edBusca}
            on:focus={entraFoco}             
            on:blur={saiFoco}             
            on:keyup={changeBuscaInterna}
            bind:value={textoBusca}
            placeholder="Selecione um Procedimento">
        {#if mostrarLista}
            <i class="fas fa-chevron-up seta"
                on:click={clickSeta}>
            </i>        
        {:else}    
            <i class="fas fa-chevron-down seta"
                on:click={clickSeta}>
            </i>    
        {/if}   
    {/if}        
    {#if mostrarLista}
        <div bind:this={elementLista} class="lista" on:scroll={(e) => onScroll(e)}>
            {#each (dataProvider ? dataProvider : []) as registro, idxReg}
                <div bind:this={refs[idxReg]} class="item" on:click={(e) => clickItem(registro, idxReg)}>
                    {#each (colunas ? colunas : []) as coluna, idxCol}
                        <div style="min-width: {coluna.width ? coluna.width : 'auto'}; max-width: {coluna.width ? coluna.width : 'auto'}; text-align: {coluna.textAlign ? coluna.textAlign : 'left'};">
                            {@html coluna.renderer(registro, coluna)}
                        </div>
                    {/each}        
                </div>
            {/each}
        </div>
    {/if}
</div>

{#if mostrarLista}
    <div class="overlay" on:click={clickFecharLista}></div>
{/if}


<svelte:options tag="svelte-select" />