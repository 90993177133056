<script>
import { createEventDispatcher } from "svelte";


let mesSelecionado = new Date().getMonth()+1;
let anoSelecionado = new Date().getFullYear();
let datas = [];
let linhasDatas = [];
let diasSemana = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];
let meses = ['JANEIRO', 'FEVEREIRO', 'MARÇO', 'ABRIL', 'MAIO', 'JUNHO', 'JULHO', 'AGOSTO', 'SETEMBRO', 'OUTUBRO', 'NOVEMBRO', 'DEZEMBRO'];
let hoje = formatarData(new Date());
let dataSelecionadaAnterior;
export let dataSelecionada;

const dispatch = createEventDispatcher();

carregarDias();

function formatarData(date) {
  let ano = date.getFullYear();
  let mes = date.getMonth();
  let dia = date.getDate();							
  mes += 1;
  return (dia < 10 ? '0'+dia : dia) + '/' + (mes < 10 ? '0'+mes : mes) + '/' + (ano);
}

function stringToDate(strDate) {
  let ano = Number(strDate.substring(6, 10));
  let mes = Number(strDate.substring(3, 5)) - 1;
  let dia = Number(strDate.substring(0, 2));
  return new Date(ano, mes, dia, 0, 0, 0, 0);
}

function carregarDias() {
  linhasDatas = [];
  let dti = new Date(anoSelecionado, mesSelecionado-1, 1, 0, 0, 0, 0);
  let dtf = new Date(anoSelecionado, mesSelecionado, 0, 0, 0, 0, 0);
  let dt = new Date(anoSelecionado, mesSelecionado-1, 1, 0, 0, 0, 0);
  let d;
  let linha = [];
  while (dt.getDay() != 0) {    
    dt.setDate(dt.getDate()-1);    
  }
  while (dt.getTime() <= dtf.getTime()) {
    d = {};
    d.dia = dt.getDate();
    d.mes = dt.getMonth()+1;
    d.ano = dt.getFullYear();
    d.data = formatarData(dt);
    d.dia_semana = diasSemana[dt.getDay()];
    if (linha.length == 7) {
      linhasDatas.push(linha);
      linha = [];
    }
    linha.push(d);
    dt.setDate(dt.getDate()+1)
  }  
  while (dt.getDay() != 0) {
    d = {};
    d.dia = dt.getDate();
    d.mes = dt.getMonth()+1;
    d.ano = dt.getFullYear();    
    d.data = formatarData(dt);
    d.dia_semana = diasSemana[dt.getDay()];        
    if (linha.length == 7) {
      linhasDatas.push(linha);
      linha = [];
    }
    linha.push(d);
    dt.setDate(dt.getDate()+1);
  }
  if (linha.length <= 7) {
    linhasDatas.push(linha);
  }
}

function clickData(dt) {
  dataSelecionada = dt.data;
  dispatch('change', dt);    
}

function clickMesAntes() {
  if (mesSelecionado > 1) {
    mesSelecionado = mesSelecionado - 1;  
  } else {
    mesSelecionado = 12;
    anoSelecionado = anoSelecionado - 1;
  }
  carregarDias();
}

function clickMesDepois() {
  if (mesSelecionado < 12) {
    mesSelecionado = mesSelecionado + 1;  
  } else {
    mesSelecionado = 1;
    anoSelecionado = anoSelecionado + 1;
  }
  carregarDias();
}

function handleClickOutside(event) {
  console.log('clicou fora');
  dispatch('clicouFora');
}

$: if (dataSelecionada && (!dataSelecionadaAnterior || dataSelecionada != dataSelecionadaAnterior)) {  
  let dt = stringToDate(dataSelecionada);
  let mesAnterior = mesSelecionado;
  let anoAnterior = anoSelecionado;
  mesSelecionado = dt.getMonth()+1;
  anoSelecionado = dt.getFullYear();
  if (mesSelecionado != mesAnterior || anoSelecionado != anoAnterior) {    
    carregarDias();
  }
  dataSelecionadaAnterior = dataSelecionada;
}

</script>


<style>
  .hgroup {
    display: flex;
    flex-direction: row;
    width: fit-content;
    align-items: stretch;
  }
  .hspacer {
    flex: 1;
  }
  .cabecalho {    
    background-color: #f0f0f0;
  }
  .cabecalhoMes {
    background-color: #d3d3d3;
    padding: 5px 0;
    justify-content: center;
    width: 100%;
    font-weight: bold;
    align-items: center;
    font-size: small;
  }
  .calendario {
    position: relative;
    top: 35px;    
    left: 0px;  
    border: 1px solid #d3d3d3;
    border-radius: 3px;
    background-color: #fff;
    z-index: 200000000;
  }
  .diaSem {        
    width: 35px;
    text-align: center;
    padding: 5px 0;
    margin: 5px;     
    cursor: pointer;   
    font-size: small;
  }
  .diaFora {        
    width: 35px;
    text-align: center;
    padding: 5px 0;
    margin: 5px;        
    cursor: pointer;
    color: #d3d3d3;
    font-size: small;
  }
  .diaHoje {        
    width: 35px;
    text-align: center;
    padding: 5px 0;
    margin: 5px;     
    cursor: pointer;   
    color: royalblue;
    border: 1px solid royalblue;
    font-size: small;
  }
  .diaSelecionado {        
    width: 35px;
    text-align: center;
    padding: 5px 0;
    margin: 5px;     
    cursor: pointer;   
    color: #fff;
    background-color: royalblue;
    font-size: small;
  }
  .btnMovMes {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    background-color: rgba(0, 0, 0, 0);
    border: none;    
    outline: none;
  }
</style>

<div class="calendario">
  <div class="hgroup cabecalhoMes">
    <button class="btnMovMes" on:click={clickMesAntes}><i class="fas fa-angle-left"></i></button>
    <div class="hspacer"></div>
    <div style="margin-right: 5px;">{meses[mesSelecionado-1]}</div>
    <div>{anoSelecionado}</div>
    <div class="hspacer"></div>
    <button class="btnMovMes" on:click={clickMesDepois}><i class="fas fa-angle-right"></i></button>
  </div>
  <div class="hgroup cabecalho">
    <div class="diaSem">DOM</div>
    <div class="diaSem">SEG</div>
    <div class="diaSem">TER</div>
    <div class="diaSem">QUA</div>
    <div class="diaSem">QUI</div>
    <div class="diaSem">SEX</div>
    <div class="diaSem">SAB</div>
  </div>
  {#each linhasDatas as linha}
    <div class="hgroup">
      {#each linha as data}   
        {#if data.data == dataSelecionada} 
          <div class="diaSelecionado" on:click={() => clickData(data)}>{data.dia}</div>
        {:else}
          {#if data.data == hoje}
            <div class="diaHoje" on:click={() => clickData(data)}>{data.dia}</div>
          {:else}
            {#if data.mes != mesSelecionado || data.ano != anoSelecionado}   
              <div class="diaFora" on:click={() => clickData(data)}>{data.dia}</div>
            {:else}
              <div class="diaSem" on:click={() => clickData(data)}>{data.dia}</div>
            {/if}
          {/if}
        {/if}
      {/each}
    </div>
  {/each}
</div>


<svelte:options tag="svelte-calendario" />