<script>
  import { createEventDispatcher } from 'svelte';

  export let dataProvider = [];
  export let labelField;
  export let editField;
  export let objectSource;  
  export let keyField;
  export let disabled = false;
  export let selecionado = null;
  let valorAnterior;

  const dispatch = createEventDispatcher();

  function setObjectSource() {    
    if (objectSource && editField && keyField) {
      if (selecionado) {
        objectSource[editField] = selecionado[keyField];
      } else {
        objectSource[editField] = null;
      }
    }
  }

  function changeCombo() {    
    setObjectSource();
    dispatch('change', selecionado);    
  }	

  function selecionarValorGravado() {    
    if (objectSource && editField && keyField && objectSource[editField]) {
      for (let i = 0; i < dataProvider.length; i++) {        
        if (dataProvider[i][keyField] == objectSource[editField]) {
          selecionado = dataProvider[i];
          break;
        }
      }
    }
  }
  
  selecionarValorGravado();

  $: if (objectSource && editField && keyField) {    
    if (objectSource[editField] && objectSource[editField] != valorAnterior) {     
      valorAnterior = objectSource[editField];      
      if (selecionado) {
        if (selecionado[keyField] != objectSource[editField]) {
          selecionarValorGravado();
        }
      } else {
        selecionarValorGravado();
      }
    } else {      
      if (objectSource[editField] != valorAnterior) {
        selecionado = null;
        valorAnterior = null;
      }
    }
  }

</script>

<select bind:value={selecionado} on:blur={changeCombo} disabled={disabled}
  style="border: 1px solid #000; border-radius: 3px; padding: 5px; width: inherit;">
  <option value={null}>Nenhum</option>    
  {#each dataProvider as item}
    <option value={item}>{item[labelField]}</option>    
  {/each}  
</select>

<svelte:options tag="svelte-combo" />
